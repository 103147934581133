import React, { Component } from 'react';
import { ggSentry } from '../../config/sentry';
import SKButton from '../UI/SKButton';

class AppErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { eventId: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    ggSentry.getInstance().withScope(scope => {
      scope.setExtras(errorInfo);
      const eventId = ggSentry.catchExceptions(error);
      this.setState({ eventId });
    });
  }

  render() {
    if (this.state.hasError) {
      //render fallback UI
      return (
        <div
          style={{
            width: '100%',
            minHeight: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            background: '#FFFFFF',
          }}
        >
          <div
            style={{
              fontSize: 24,
              color: 'var(--text-transparent-color)',
              paddingBottom: 25,
              letterSpacing: 0,
              textAlign: 'center',
              color: '#111111',
            }}
          >
            <div>Oops!</div>
            <div>Something went wrong.</div>
          </div>
          <div
            style={{
              display: 'grid',
              flexDirection: 'column',
              alignContent: 'center',
              textAlign: 'center',
              gap: 16,
            }}
          >
            <div>
              <SKButton
                fullWidth
                color="primary"
                variant="contained"
                onClick={() =>
                  ggSentry.showReportDialog({ eventId: this.state.eventId })
                }
              >
                Report feedback
              </SKButton>
            </div>
            <div>
              <SKButton
                fullWidth
                onClick={() => window.open('/', '_self')}
                color="primary"
                variant="outlined"
              >
                Go Back
              </SKButton>
            </div>
          </div>
        </div>
      );
    }

    //when there's not an error, render children untouched
    return this.props.children;
  }
}

export default AppErrorBoundary;
