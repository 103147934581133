class GGAnalytics {
  constructor() {
    this.ga =
      process.env.NODE_ENV === 'production' && typeof window !== 'undefined' && window.ga
        ? window.ga
        : (...args) => {
          // console.log('[MOCK] GA', ...args);
          // window.ga(...args);
        };
  }

  initialize(id) {
    typeof this.ga === 'function' && this.ga('create', id, 'auto');
  }

  firePageView(pathName = '') {
    typeof this.ga === 'function' && typeof window !== 'undefined' &&
      this.ga('send', {
        hitType: 'pageView',
        page: pathName || window.location.pathname,
      });
  }

  fireGAEvent(event) {
    typeof this.ga === 'function' &&
      this.ga('send', { hitType: 'event', ...event });
  }
}

export const ggAnalytics = new GGAnalytics();
