export const StorageService = {
  get redirectUrl() {
    return _localstorage.getItem("callbackUrl", true);
  },
  set redirectUrl(value) {
    _localstorage.setItem("callbackUrl", value, true);
  },
  getAccessToken() {
    return _localstorage.getItem("accessToken");
  },
  setAccessToken(value) {
    return _localstorage.setItem("accessToken", value);
  },
  getRefreshToken() {
    return _localstorage.getItem("refreshToken");
  },
  setRefreshToken(value) {
    return _localstorage.setItem("refreshToken", value);
  },
  getWId() {
    return _localstorage.getItem("wid");
  },
  setWId(value) {
    return _localstorage.setItem("wid", value);
  },
  getXPN() {
    return _localstorage.getItem("x-pn");
  },
  setXPN(value) {
    return _localstorage.setItem("x-pn", value);
  },
  getUserId() {
    return _localstorage.getItem("userId");
  },
  setUserId(value) {
    return _localstorage.setItem("userId", value);
  },
  getMobile() {
    return _localstorage.getItem("mob");
  },
  setMobile(value) {
    return _localstorage.setItem("mob", value);
  },
  setReferralCode(value) {
    return _localstorage.setItem("referralCode", value);
  },
  getReferralCode() {
    return _localstorage.getItem("referralCode");
  },
  getCountryCode() {
    return _localstorage.getItem("countryCode");
  },
  setCountryCode(value) {
    return _localstorage.setItem("countryCode", value);
  },
  get(key, fromSessionStorage = false) {
    return _localstorage.getItem(key, fromSessionStorage);
  },
  set(key, value, saveToSessionStorage = false) {
    return _localstorage.setItem(key, value, saveToSessionStorage);
  },
};

const _localstorage = {
  getItem(key, fromSessionStorage = false) {
    key = generateKey(key);
    const storage = selectStorage(fromSessionStorage);

    const val = storage?.getItem(key);

    if (val === null) return undefined;

    try {
      return JSON.parse(val);
    } catch (error) {
      return val; // if not, simply return the value.
    }
  },

  setItem(key, value, saveToSessionStorage = false) {
    key = generateKey(key);
    const storage = selectStorage(saveToSessionStorage);

    if (value === undefined || value === null) {
      return storage?.removeItem(key);
    }

    if (typeof value === "object") {
      value = JSON.stringify(value);
    }

    storage?.setItem(key, value);
  },
};

function selectStorage(useSessionStorage) {
  if (typeof window === "undefined") return null;

  return !useSessionStorage ? localStorage : sessionStorage;
}

function generateKey(key) {
  return "__rupiyo__." + key;
}
