import { StorageService } from "../service/Storage";

export const ENV =
  process.env.NEXT_PUBLIC_ENV === "development" ? "development" : "production";
export const isProduction = ENV === "production";

export const isDev = ENV === "development";

export const isLocalhost = Boolean(
  typeof window !== "undefined" &&
    (window.location.hostname === "localhost" ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === "[::1]" ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      ))
);
const __TWEAK_API_ENDPOINT__ = "__TWEAK_API_ENDPOINT__";
export const API_END_POINT =
  !isProduction && StorageService.get(__TWEAK_API_ENDPOINT__)
    ? StorageService.get(__TWEAK_API_ENDPOINT__)
    : process.env.NEXT_PUBLIC_API_END_POINT;

export const PLAY_STORE_URL =
  "https://play.google.com/store/apps/details?id=com.rupiyo.realmoney.rewardsapp";
